@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://use.typekit.net/grl6fnc.css');

body {
    margin: 0;
    font-family: 'Nunito', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #261326;
    background-color: #fdfaff;
    background-image:  linear-gradient(rgba(251, 210, 255, 0.35) 1.3px, transparent 1.3px), linear-gradient(to right, rgba(251, 210, 255, 0.35) 1.3px, #fdfaff 1.3px);
    background-size: 26px 26px;
 }

.main-container {
    margin-top: 6.7rem;
}

#main-navigation {
    border-bottom: 1px #f9b4fd solid;
}

.navbar-brand {
    /*font-family: 'Nunito', sans-serif;*/
    /*font-weight: 900;*/
    font-family: "modern-love", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5em;
    color: #261326;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

a:hover {
    color: #f9b4fd !important;
}

h1, h2, h3, h4, h5 {
    /*font-family: 'Nunito', sans-serif;*/
    /*font-weight: 900;*/
    font-family: "modern-love", sans-serif;
    font-weight: 400;
    font-style: normal;
}

h2 {
    font-size: 3em;
}

p.text {
    font-size: 1.5em;
}

#apropos-page .avatar-container img {
    width: 100%;
    height: auto;
}

#apropos-page .row {
    margin-bottom: 6em;
}

label {
    font-size: 1.3em;
}

#contact-page .img-container img {
    width: 100%;
    height: auto;
}

#contact-page .row {
    margin-bottom: 6em;
}

a.link-mail {
    color: #f9b4fd !important;
    transition: all .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

a.link-mail:hover {
    color: #c37ac7 !important;
}

.fixed-top {
    z-index: 1!important;
}